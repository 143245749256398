import React from "react";

const CompanyInfoBox = (props) => {
  return (
    <div className="comp-info-box">
      <div className="ci-elements">
        <img className="ci-img" src={props.img} alt="INFO_IMG" />
        <div className="ci-text">{props.text}</div>
      </div>
    </div>
  );
};

export default CompanyInfoBox;
