/*
 *
 * website Review actions
 *
 */

import { success } from "react-notification-system-redux";
import axios from "axios";

import {
  FETCH_WEBSITE_REVIEWS,
  SET_WEBSITE_REVIEWS_LOADING,
} from "./constants";
import handleError from "../../utils/error";
import { allFieldsValidation } from "../../utils/validation";

// fetch website reviews api
export const fetchWebsiteReviews = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_WEBSITE_REVIEWS_LOADING, payload: true });

      const response = await axios.get(`/api/websiteReview`);

      dispatch({ type: FETCH_WEBSITE_REVIEWS, payload: response.data });
      console.log("website re", response.data);
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch({ type: SET_WEBSITE_REVIEWS_LOADING, payload: false });
    }
  };
};
