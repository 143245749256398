/**
 *
 * SocialShare
 *
 */

import React from "react";

import {
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookShareButton,
} from "react-share";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const SocialShare = (props) => {
  const { product } = props;

  const shareMsg = `Hey,
Try out the ${
    product.name
  } by SleepElements!

Here's the link, ${
    window.location.protocol !== "https" ? "http" : "https"
  }://${window.location.host}/product/${product.slug}`;

  return (
    <ul className="d-flex flex-row mx-0 mb-0 justify-content-center justify-content-md-start share-box">
      <li>
        <WhatsappShareButton url={`${shareMsg}`} className="share-btn whatsapp">
          <WhatsAppIcon/>
        </WhatsappShareButton>
      </li>
    </ul>
  );
};

export default SocialShare;
