import React from "react";
import quote from "../../images/Sleep Elements Resources/left-quote.png";
import lazy from "../../images/Sleep Elements Resources/lazy.jpg";
import circledleft from "../../images/Sleep Elements Resources/circled-left.png";
import circledright from "../../images/Sleep Elements Resources/circled-right.png";
import "./WebsiteReviews.css";
import Avatar from "react-avatar";
import ReactStars from "react-rating-stars-component";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import { connect } from "react-redux";
import { fetchWebsiteReviews } from "./actions";
import "./WebsiteReviews.css";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";

class Reviews extends React.PureComponent {
  render() {
    return (
      <div className="Reviews-cont">
        <body>
          <section id="testimonials">
            {/* <!--testimonials-box-container------> */}
            <div class="testimonial-box-container">
              {/* <!--BOX-1--------------> */}
              <div class="testimonial-box">
                {/* <!--top-------------------------> */}
                <div class="box-top">
                  {/* <!--profile-----> */}
                  <div class="profile">
                    {/* <!--img----> */}
                    <div class="profile-img">
                      <img src="https://icons.iconarchive.com/icons/papirus-team/papirus-status/256/avatar-default-icon.png" />
                    </div>
                    {/* <!--name-and-username--> */}
                    <div class="name-user">
                      <strong>Danish</strong>
                      <span>@danish786</span>
                    </div>
                  </div>
                  {/* <!--reviews------> */}
                  <ReactStars
                    classNames="mt-1 mt-lg-2"
                    size={16}
                    edit={false}
                    color={"#adb5bd"}
                    activeColor={"#ffb302"}
                    a11y={true}
                    isHalf={true}
                    emptyIcon={<StarIcon />}
                    halfIcon={<StarHalfIcon />}
                    filledIcon={<StarIcon />}
                    value={4}
                  />
                </div>
                {/* <!--Comments----------------------------------------> */}
                <div class="client-comment">
                  <h4>Great Prices</h4>
                  <p>I recommend this website</p>
                </div>
              </div>
            </div>
          </section>
          <section id="testimonials">
            {/* <!--testimonials-box-container------> */}
            <div class="testimonial-box-container">
              {/* <!--BOX-1--------------> */}
              <div class="testimonial-box">
                {/* <!--top-------------------------> */}
                <div class="box-top">
                  {/* <!--profile-----> */}
                  <div class="profile">
                    {/* <!--img----> */}
                    <div class="profile-img">
                      <img src="https://icons.iconarchive.com/icons/papirus-team/papirus-status/256/avatar-default-icon.png" />
                    </div>
                    {/* <!--name-and-username--> */}
                    <div class="name-user">
                      <strong>Rajarshi</strong>
                      <span>@rajarshi233</span>
                    </div>
                  </div>
                  {/* <!--reviews------> */}
                  <ReactStars
                    classNames="mt-1 mt-lg-2"
                    size={16}
                    edit={false}
                    color={"#adb5bd"}
                    activeColor={"#ffb302"}
                    a11y={true}
                    isHalf={true}
                    emptyIcon={<StarIcon />}
                    halfIcon={<StarHalfIcon />}
                    filledIcon={<StarIcon />}
                    value={5}
                  />
                </div>
                {/* <!--Comments----------------------------------------> */}
                <div class="client-comment">
                  <h4>Good price</h4>
                  <p>At good and best price</p>
                </div>
              </div>
            </div>
          </section>
          <section id="testimonials">
            {/* <!--testimonials-box-container------> */}
            <div class="testimonial-box-container">
              {/* <!--BOX-1--------------> */}
              <div class="testimonial-box">
                {/* <!--top-------------------------> */}
                <div class="box-top">
                  {/* <!--profile-----> */}
                  <div class="profile">
                    {/* <!--img----> */}
                    <div class="profile-img">
                      <img src="https://icons.iconarchive.com/icons/papirus-team/papirus-status/256/avatar-default-icon.png" />
                    </div>
                    {/* <!--name-and-username--> */}
                    <div class="name-user">
                      <strong>Ayaan</strong>
                      <span>@Ayaan7654</span>
                    </div>
                  </div>
                  {/* <!--reviews------> */}
                  <ReactStars
                    classNames="mt-1 mt-lg-2"
                    size={16}
                    edit={false}
                    color={"#adb5bd"}
                    activeColor={"#ffb302"}
                    a11y={true}
                    isHalf={true}
                    emptyIcon={<StarIcon />}
                    halfIcon={<StarHalfIcon />}
                    filledIcon={<StarIcon />}
                    value={3}
                  />
                </div>
                {/* <!--Comments----------------------------------------> */}
                <div class="client-comment">
                  <h4>Product</h4>
                  <p>I recommend this website you will get lots of good products</p>
                </div>
              </div>
            </div>
          </section>
        </body>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    websiteReviewsState: state.websiteReview.websiteReviews,
  };
};

export default connect(mapStateToProps, { fetchWebsiteReviews })(Reviews);
