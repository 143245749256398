/*
 *
 * Product constants
 *
 */

export const FETCH_PRODUCTS = "src/Product/FETCH_PRODUCTS";
export const FETCH_STORE_PRODUCTS = "src/Product/FETCH_STORE_PRODUCTS";
export const FETCH_PRODUCT = "src/Product/FETCH_PRODUCT";
export const FETCH_STORE_PRODUCT = "src/Product/FETCH_STORE_PRODUCT";
export const PRODUCT_CHANGE = "src/Product/PRODUCT_CHANGE";
export const PRODUCT_EDIT_CHANGE = "src/Product/PRODUCT_EDIT_CHANGE";
export const PRODUCT_SHOP_CHANGE = "src/Product/PRODUCT_SHOP_CHANGE";
export const SET_PRODUCT_FORM_ERRORS = "src/Product/SET_PRODUCT_FORM_ERRORS";

export const PRODUCT_IMAGE_UPLOAD = "src/Product/PRODUCT_IMAGE_UPLOAD";

export const SET_PRODUCT_FORM_EDIT_ERRORS =
  "src/Product/SET_PRODUCT_FORM_EDIT_ERRORS";
export const SET_PRODUCT_SHOP_FORM_ERRORS =
  "src/Product/SET_PRODUCT_SHOP_FORM_ERRORS";
export const RESET_PRODUCT = "src/Product/RESET_PRODUCT";
export const RESET_PRODUCT_SHOP = "src/Product/RESET_PRODUCT_SHOP";
export const ADD_PRODUCT = "src/Product/ADD_PRODUCT";
export const REMOVE_PRODUCT = "src/Product/REMOVE_PRODUCT";
export const FETCH_PRODUCTS_SELECT = "src/Product/FETCH_PRODUCTS_SELECT";
export const SET_PRODUCTS_LOADING = "src/Product/SET_PRODUCTS_LOADING";
export const SET_ADVANCED_FILTERS = "src/Product/SET_ADVANCED_FILTERS";
export const RESET_ADVANCED_FILTERS = "src/Product/RESET_ADVANCED_FILTERS";
