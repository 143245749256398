/*
 *
 * Customer
 *
 */

import React from "react";

import { connect } from "react-redux";

import actions from "../../actions";

import SubPage from "../../components/Manager/SubPage";
import OrderList from "../../components/Manager/OrderList";
import OrderSearch from "../../components/Manager/OrderSearch";
import NotFound from "../../components/Common/NotFound";
import LoadingIndicator from "../../components/Common/LoadingIndicator";
import OrderList2 from "../../components/Manager/OrderList2";

class Customer extends React.PureComponent {
  // componentDidMount() {
  //   this.props.fetchOrders();
  // }
  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      DataisLoaded: false,
    };
  }

  // ComponentDidMount is used to
  // execute the code
  componentDidMount() {
    fetch("http://localhost:5000/api/order/allorder")
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          orders: json,
          DataisLoaded: true,
        });
      });
  }

  render() {
    const { history, user, isLoading, searchOrders } = this.props;
    const { DataisLoaded, orders } = this.state;
    console.log("76orders:", orders);

    return (
      <div className="order-dashboard">
        <SubPage
          title="Customer Orders"
          actionTitle="My Orders"
          handleAction={() =>
            user.role === "ROLE_ADMIN" && history.push("/dashboard/orders")
          }
        >
          <OrderSearch onSearchSubmit={searchOrders} />
          {isLoading ? (
            <LoadingIndicator inline />
          ) : user.role === "ROLE_ADMIN" ? (
            orders?.orders?.length > 0 ? (
              <OrderList2 orders={orders} />
            ) : (
              <>
              {/* <LoadingIndicator inline /> */}
              <NotFound message="No orders found." />
              </>
            )
          ) : orders?.length > 0 ? (
            <OrderList orders={orders} />
          ) : (
            <NotFound message="No orders found." />
          )}
        </SubPage>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    orders: state.order.searchedOrders,
    isLoading: state.order.isLoading,
    isOrderAddOpen: state.order.isOrderAddOpen,
  };
};

export default connect(mapStateToProps, actions)(Customer);
