/*
 *
 * Merchant constants
 *
 */

export const FETCH_MERCHANTS = "src/Merchant/FETCH_MERCHANTS";
export const REMOVE_MERCHANT = "src/Merchant/REMOVE_MERCHANT";
export const SELL_FORM_CHANGE = "src/Merchant/SELL_FORM_CHANGE";
export const SET_SELL_FORM_ERRORS = "src/Merchant/SET_SELL_FORM_ERRORS";
export const SELL_FORM_RESET = "src/Merchant/SELL_FORM_RESET";
export const SIGNUP_CHANGE = "src/Merchant/SIGNUP_CHANGE";
export const SET_SIGNUP_FORM_ERRORS = "src/Merchant/SET_SIGNUP_FORM_ERRORS";
export const SET_MERCHANTS_LOADING = "src/Merchant/SET_MERCHANTS_LOADING";
export const SET_SELL_SUBMITTING = "src/Merchant/SET_SELL_SUBMITTING";
export const SET_SELL_LOADING = "src/Merchant/SET_SELL_LOADING";
export const SIGNUP_RESET = "src/Merchant/SIGNUP_RESET";
