import React from "react";
import InfoBox from "../components/InfoBox";
import calendar from "../images/calendar.png";
import reload from "../images/circle-of-arrows.png";
import leaf from "../images/leaf.png";
import rna from "../images/rna.png";


const InfoSection = (props) => {
  return (
    <div>
      <div className="infocont">
        <InfoBox
          img={leaf}
          title="Organic cotton"
          details="Best quality fibre in the market perfect for sound sleep."
        />
        <InfoBox
          img={rna}
          title="Sturdy & Soft Springs"
          details="High quality springs that takes your weight and evenly distribute"
        />
        <InfoBox
          img={reload}
          title="Flippable Mattress"
          details="Use one mattress as two, Flip N Turn and ready to use"
        />
        {/* <InfoBox
          img={calendar}
          title="20 years of Experience"
          details="Trusted by all over the country"
        /> */}
      </div>
    </div>
  );
};

export default InfoSection;
