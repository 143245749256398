/*
 *
 * Brand constants
 *
 */

export const FETCH_BRANDS = "src/Brand/FETCH_BRANDS";
export const FETCH_STORE_BRANDS = "src/Brand/FETCH_STORE_BRANDS";
export const FETCH_BRAND = "src/Brand/FETCH_BRAND";
export const BRAND_CHANGE = "src/Brand/BRAND_CHANGE";
export const BRAND_EDIT_CHANGE = "src/Brand/BRAND_EDIT_CHANGE";
export const SET_BRAND_FORM_ERRORS = "src/Brand/SET_BRAND_FORM_ERRORS";
export const SET_BRAND_FORM_EDIT_ERRORS =
  "src/Brand/SET_BRAND_FORM_EDIT_ERRORS";
export const RESET_BRAND = "src/Brand/RESET_BRAND";
export const ADD_BRAND = "src/Brand/ADD_BRAND";
export const REMOVE_BRAND = "src/Brand/REMOVE_BRAND";
export const FETCH_BRANDS_SELECT = "src/Brand/FETCH_BRANDS_SELECT";
export const SET_BRANDS_LOADING = "src/Brand/SET_BRANDS_LOADING";
