/**
 *
 * CartSummary
 *
 */

import React from "react";
import { Col, Container, Row } from "reactstrap";

const CartSummary = (props) => {
  const { cartTotal } = props;

  return (
    <div className="cart-summary">
      <Container>
        <Row className="mb-2 summary-item">
          <Col xs="9">
            <p className="summary-label">Free Shipping</p>
          </Col>
          <Col xs="3" className="text-right">
            <p className="summary-value">₹0</p>
          </Col>
        </Row>
        <Row className="mb-2 summary-item">
          {localStorage.getItem("Price") && (
            <>
              <Col xs="9">
                <p className="summary-label">Total</p>
              </Col>
              <Col xs="3" className="text-right">
                <p className="value price">₹{localStorage.getItem("Price")}</p>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default CartSummary;
