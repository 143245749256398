/**
 *
 * Navigation
 *
 */

import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import React from "react";
import Autosuggest from "react-autosuggest";
import { connect } from "react-redux";
import { Link, NavLink as ActiveLink, withRouter } from "react-router-dom";
import {
  Col, Container,







  Dropdown,


  DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar,

  NavItem,
  NavLink, Row,





  UncontrolledDropdown
} from "reactstrap";
import actions from "../../actions";
import Button from "../../components/Common/Button";
import CartIcon from "../../components/Common/CartIcon";
import { BarsIcon } from "../../components/Common/Icon";
import MiniBrand from "../../components/Store//MiniBrand";
import logo from "../../images/Icon.png";
import Cart from "../Cart";
import Menu from "../NavigationMenu";
import CallIcon from '@mui/icons-material/Call';
import CheckIcon from '@mui/icons-material/Check';
import CollectionsIcon from '@mui/icons-material/Collections';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



class Navigation extends React.PureComponent {
  componentDidMount() {
    this.props.fetchStoreBrands();
    this.props.fetchStoreCategories();
    const slug = this.props.match.params.slug;
    this.props.filterProducts(slug);
  }

  toggleBrand() {
    this.props.fetchStoreBrands();
    this.props.toggleBrand();
  }

  toggleMenu() {
    this.props.fetchStoreCategories();
    this.props.toggleMenu();
  }

  getSuggestionValue(suggestion) {
    return suggestion.name;
  }

  renderSuggestion(suggestion, { query, isHighlighted }) {
    const BoldName = (suggestion, query) => {
      const matches = AutosuggestHighlightMatch(suggestion.name, query);
      const parts = AutosuggestHighlightParse(suggestion.name, matches);

      return (
        <div>
          {parts.map((part, index) => {
            const className = part.highlight
              ? "react-autosuggest__suggestion-match"
              : null;
            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </div>
      );
    };

    return (
      <Link to={`/product/${suggestion.slug}`}>
        <div className="d-flex">
          <img
            className="item-image"
            src={`${
              suggestion.imageUrl
                ? suggestion.imageUrl
                : "/images/placeholder-image.png"
            }`}
          />
          <div>
            <Container>
              <Row>
                <Col>
                  <span className="name">{BoldName(suggestion, query)}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="price">{suggestion.slug}</span>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    const {
      history,
      authenticated,
      user,
      cartItems,
      brands,
      categories,
      signOut,
      isMenuOpen,
      isCartOpen,
      isBrandOpen,
      toggleCart,
      toggleMenu,
      searchValue,
      suggestions,
      onSearch,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
    } = this.props;

    const inputProps = {
      placeholder: "Search Products",
      value: searchValue,
      onChange: (_, { newValue }) => {
        onSearch(newValue);
      },
    };

    return (
      <header className="header fixed-mobile-header">
        <div className="header-info">
          <Container>
            <Row>
              <Col md="4" className="text-center d-none d-md-block">
                {/* <i className="fa fa-truck" /> */}
                <CheckIcon style={{color: "white"}}/>
                <span>10yrs Warranty</span>
              </Col> 
              <Col md="4" className="text-center d-none d-md-block">
                {/* <i className="fa fa-truck" /> */}
                <CollectionsIcon style={{color: "white"}}/>
                <span>Exclusive collection</span>
              </Col> 
              {/* <Col md="4" className="text-center d-none d-md-block">
                <i className="fa fa-truck" />
                <span>Free Shipping</span>
              </Col>  */}
              <Col md="4" className="text-center d-none d-md-block">
                {/* <i className="fa fa-phone" /> */}
                <CallIcon style={{color: "white"}}/>
                <span>Call us 089-999-09449</span>
              </Col>
              <Col xs="12" className="text-center d-block d-md-none">
              <CallIcon style={{color: "white"}}/>
                <span> Need advice? Call us 089-999-09449</span>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <Row className="align-items-center top-header">
            <Col
              xs={{ size: 12, order: 1 }}
              sm={{ size: 12, order: 1 }}
              md={{ size: 3, order: 1 }}
              lg={{ size: 3, order: 1 }}
            >
              <div className="brand">
                {categories && categories.length > 0 && (
                  <Button
                    borderless
                    variant="empty"
                    className="d-none d-md-block"
                    ariaLabel="open the menu"
                    icon={<MenuIcon />}
                    onClick={() => this.toggleMenu()}
                  />
                )}
                <Link to="/">
                  <div className="logo-header">
                    <img
                      className="logo"
                      src={logo}
                      alt="logo"
                      style={{ cursor: "pointer" }}
                    />
                    <h1 className="logo">Sleep Elements</h1>
                  </div>
                </Link>
              </div>
            </Col>
            <Col
              xs={{ size: 12, order: 4 }}
              sm={{ size: 12, order: 4 }}
              md={{ size: 12, order: 4 }}
              lg={{ size: 5, order: 2 }}
              className="pt-2 pt-lg-0"
            >
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={(_, item) => {
                  history.push(`/product/${item.suggestion.slug}`);
                }}
              />      
            </Col>
            <Col
              xs={{ size: 12, order: 2 }}
              sm={{ size: 12, order: 2 }}
              md={{ size: 4, order: 1 }}
              lg={{ size: 5, order: 3 }}
              className="desktop-hidden"
            >
              <div className="header-links">
                <Button
                  borderless
                  variant="empty"
                  ariaLabel="open the menu"
                  icon={<MenuIcon />}
                  onClick={() => this.toggleMenu()}
                />
                <CartIcon cartItems={cartItems} onClick={toggleCart} />
              </div>
            </Col>
            <Col
              xs={{ size: 12, order: 2 }}
              sm={{ size: 12, order: 2 }}
              md={{ size: 9, order: 1 }}
              lg={{ size: 4, order: 3 }}
              // className='px-0'
            >
              <Navbar color="light" light expand="md" className="mt-1 mt-md-0">
                <CartIcon
                  className="d-none d-md-block"
                  cartItems={cartItems}
                  onClick={toggleCart}
                />
                <Nav navbar>
                  {brands && brands.length > 0 && (
                    <Dropdown
                      nav
                      inNavbar
                      toggle={() => this.toggleBrand()}
                      isOpen={isBrandOpen}
                    >
                      <DropdownToggle nav>
                        Brands
                        <KeyboardArrowDownIcon />
                      </DropdownToggle>
                      <DropdownMenu right className="nav-brand-dropdown">
                        <div className="mini-brand">
                          <MiniBrand
                            brands={brands}
                            toggleBrand={() => this.toggleBrand()}
                          />
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  )}
                  <NavItem>
                    <NavLink
                      tag={ActiveLink}
                      to="/shop"
                      activeClassName="active"
                    >
                      Shop
                    </NavLink>
                  </NavItem>
                  {authenticated ? (
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav>
                        {user.firstName ? user.firstName : "Welcome"}
                        <KeyboardArrowDownIcon />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          onClick={() => history.push("/dashboard")}
                        >
                          Dashboard
                        </DropdownItem>
                        <DropdownItem onClick={signOut}>Sign Out</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  ) : (
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav>
                        Welcome!
                        <KeyboardArrowDownIcon />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={() => history.push("/login")}>
                          Login
                        </DropdownItem>
                        <DropdownItem onClick={() => history.push("/register")}>
                          Sign Up
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </Nav>
              </Navbar>
            </Col>
          </Row>
        </Container>

        {/* hidden cart drawer */}
        <div
          className={isCartOpen ? "mini-cart-open" : "hidden-mini-cart"}
          aria-hidden={`${isCartOpen ? false : true}`}
        >
          <div className="mini-cart">
            <Cart />
          </div>
          <div
            className={
              isCartOpen ? "drawer-backdrop dark-overflow" : "drawer-backdrop"
            }
            onClick={toggleCart}
          />
        </div>

        {/* hidden menu drawer */}
        <div
          className={isMenuOpen ? "mini-menu-open" : "hidden-mini-menu"}
          aria-hidden={`${isMenuOpen ? false : true}`}
        >
          <div className="mini-menu">
            <Menu />
          </div>
          <div
            className={
              isMenuOpen ? "drawer-backdrop dark-overflow" : "drawer-backdrop"
            }
            onClick={toggleMenu}
          />
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMenuOpen: state.navigation.isMenuOpen,
    isCartOpen: state.navigation.isCartOpen,
    isBrandOpen: state.navigation.isBrandOpen,
    cartItems: state.cart.cartItems,
    brands: state.brand.storeBrands,
    categories: state.category.storeCategories,
    authenticated: state.authentication.authenticated,
    user: state.account.user,
    searchValue: state.navigation.searchValue,
    suggestions: state.navigation.searchSuggestions,
  };
};

export default connect(mapStateToProps, actions)(withRouter(Navigation));
