/**
 *
 * ProductPage
 *
 */

import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import actions from "../../actions";
import Button from "../../components/Common/Button";
import { BagIcon } from "../../components/Common/Icon";
import Input from "../../components/Common/Input";
import LoadingIndicator from "../../components/Common/LoadingIndicator";
import NotFound from "../../components/Common/NotFound";
import ProductReviews from "../../components/Store/ProductReviews";
import SocialShare from "../../components/Store/SocialShare";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Radio } from "antd";
import "../../components/size.css";
import SizeBox from "../../components/size";
// const { TabPane } = Tabs;
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";

class ProductPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      category: 1,
      categorySizes: 1,
      thickness: 6,
      length: "",
      breadth: "",
    };

    this.lengthHandleChange = this.lengthHandleChange.bind(this);
    this.breadthHandleChange = this.breadthHandleChange.bind(this);
  }

  lengthHandleChange(event) {
    this.setState({ length: event.target.value });
  }
  breadthHandleChange(event) {
    this.setState({ breadth: event.target.value });
  }

  componentDidMount() {
    const slug = this.props.match.params.slug;
    this.props.fetchStoreProduct(slug);
    this.props.fetchProductReviews(slug);
    document.body.classList.add("product-page");
    this.props.fetchProfile();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      const slug = this.props.match.params.slug;
      this.props.fetchStoreProduct(slug);
    }
    console.log(this.state);
  }

  componentWillUnmount() {
    document.body.classList.remove("product-page");
  }

  render() {
    const {
      isLoading,
      product,
      productShopData,
      shopFormErrors,
      itemsInCart,
      productShopChange,
      handleAddToCart,
      handleRemoveFromCart,
      addProductReview,
      reviewsSummary,
      reviews,
      reviewFormData,
      reviewChange,
      reviewFormErrors,
      user,
      categories,
    } = this.props;

    let basePrice = product.price;
    console.log("filter category", categories);

    // single , categorysize = 1
    if (this.state.categorySizes === 1 && this.state.category === 1) {
      basePrice = basePrice * 6 * 2.5;
    }
    // single , categorysize = 2
    if (this.state.categorySizes === 2 && this.state.category === 1) {
      basePrice = basePrice * 6 * 3;
    }
    // single , categorysize = 3
    if (this.state.categorySizes === 3 && this.state.category === 1) {
      basePrice = basePrice * 6.25 * 2.5;
    }

    // single , categorysize = 4
    if (this.state.categorySizes === 4 && this.state.category === 1) {
      basePrice = basePrice * 6.25 * 3;
    }
    // single , categorysize = 5
    if (this.state.categorySizes === 5 && this.state.category === 1) {
      basePrice = basePrice * 6.5 * 2.5;
    }
    // single , categorysize = 6
    if (this.state.categorySizes === 6 && this.state.category === 1) {
      basePrice = basePrice * 6.5 * 3;
    }
    // Double , categorysize = 7
    if (this.state.categorySizes === 7 && this.state.category === 2) {
      basePrice = basePrice * 6 * 3.5;
    }

    // Double , categorysize = 8
    if (this.state.categorySizes === 8 && this.state.category === 2) {
      basePrice = basePrice * 4 * 6.25;
    }
    // Double , categorysize = 9
    if (this.state.categorySizes === 9 && this.state.category === 2) {
      basePrice = basePrice * 4 * 6;
    }
    // Queen , categorysize = 10
    if (this.state.categorySizes === 10 && this.state.category === 3) {
      basePrice = basePrice * 5 * 5.83;
    }

    // Queen , categorysize = 11
    if (this.state.categorySizes === 11 && this.state.category === 3) {
      basePrice = basePrice * 5 * 6.25;
    }

    // Queen , categorysize = 12
    if (this.state.categorySizes === 12 && this.state.category === 3) {
      basePrice = basePrice * 5 * 6;
    }

    // King , categorysize = 13
    if (this.state.categorySizes === 13 && this.state.category === 4) {
      basePrice = basePrice * 6 * 6;
    }

    // King , categorysize = 14
    if (this.state.categorySizes === 14 && this.state.category === 4) {
      basePrice = basePrice * 6.26 * 6;
    }

    // King , categorysize = 15
    if (this.state.categorySizes === 15 && this.state.category === 4) {
      basePrice = basePrice * 6.5 * 6;
    }

    // Customize
    if (this.state.category === 5) {
      basePrice =
        basePrice * (this.state.length / 12) * (this.state.breadth / 12).toFixed(2);;
    }
    localStorage.setItem("Price", basePrice);

    return (
      <div className="product-shop">
        {isLoading ? (
          <LoadingIndicator />
        ) : Object.keys(product).length > 0 ? (
          <>
            <Row className="flex-row">
              <Col xs="12" md="5" lg="5" className="mb-3 px-3 px-md-2">
                <div className="position-relative">
                  <Carousel>
                    <div>
                      <img
                        src={`${
                          product.imageUrls
                            ? product.imageUrls[0]
                            : "/images/placeholder-image.png"
                        }`}
                      />
                    </div>
                    <div>
                      <img
                        src={`${
                          product.imageUrls
                            ? product.imageUrls[1]
                            : "/images/placeholder-image.png"
                        }`}
                      />
                    </div>
                    <div>
                      <img
                        src={`${
                          product.imageUrls
                            ? product.imageUrls[2]
                            : "/images/placeholder-image.png"
                        }`}
                      />
                    </div>
                  </Carousel>
                  {product.inventory <= 0 && !shopFormErrors["quantity"] ? (
                    <p className="stock out-of-stock">Out of stock</p>
                  ) : (
                    <p className="stock in-stock">In stock</p>
                  )}
                </div>
              </Col>
              <Col xs="12" md="7" lg="7" className="mb-3 px-3 px-md-2">
                <div className="product-container">
                  <div className="item-box">
                    <div className="item-details">
                      <h1 className="item-name one-line-ellipsis">
                        {product.name}
                      </h1>
                      <p className="sku">{product.sku}</p>
                      <hr />
                      {product.brand && (
                        <p className="by">
                          see more from{" "}
                          <Link
                            to={`/shop/brand/${product.brand.slug}`}
                            className="default-link"
                          >
                            {product.brand.name}
                          </Link>
                        </p>
                      )}
                      <p className="item-desc">{product.description}</p>
                    </div>
                    <div className="item-customize">
                      <Input
                        type={"number"}
                        error={shopFormErrors["quantity"]}
                        label={"Quantity"}
                        name={"quantity"}
                        decimals={false}
                        min={1}
                        max={product.inventory}
                        placeholder={"Product Quantity"}
                        disabled={
                          product.inventory <= 0 && !shopFormErrors["quantity"]
                        }
                        value={productShopData.quantity}
                        onInputChange={(name, value) => {
                          productShopChange(name, value);
                        }}
                      />
                    </div>
                    {product.name !== "Pillows" ? (
                      <>
                        <div className="size-conatainer">
                          {/* <SizeBox /> */}
                          <Tabs>
                            <TabList>
                              <Tab
                                onClick={() =>
                                  this.setState((prevState) => ({
                                    category: 1,
                                    categorySizes: 1,
                                  }))
                                }
                              >
                                SINGLE
                              </Tab>
                              <Tab
                                onClick={() =>
                                  this.setState((prevState) => ({
                                    category: 2,
                                    categorySizes: 7,
                                  }))
                                }
                              >
                                DOUBLE
                              </Tab>
                              <Tab
                                onClick={() =>
                                  this.setState((prevState) => ({
                                    category: 3,
                                    categorySizes: 10,
                                  }))
                                }
                              >
                                QUEEN
                              </Tab>
                              <Tab
                                onClick={() =>
                                  this.setState((prevState) => ({
                                    category: 4,
                                    categorySizes: 13,
                                  }))
                                }
                              >
                                KING
                              </Tab>
                              <Tab
                                onClick={() =>
                                  this.setState((prevState) => ({
                                    category: 5,
                                  }))
                                }
                              >
                                CUSTOMIZE
                              </Tab>
                            </TabList>

                            {/* // Tab 1 content */}
                            <TabPanel>
                              <Radio.Group name="radiogroup" defaultValue={1}>
                                <Radio
                                  value={1}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 1,
                                    }))
                                  }
                                >
                                  6’ X 2.5’
                                </Radio>
                                <Radio
                                  value={2}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 2,
                                    }))
                                  }
                                >
                                  6’ X 3’
                                </Radio>
                                <Radio
                                  value={3}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 3,
                                    }))
                                  }
                                >
                                  6.25’ X 2.5’
                                </Radio>
                                <Radio
                                  value={4}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 4,
                                    }))
                                  }
                                >
                                  6.25’ X 3’
                                </Radio>
                                <Radio
                                  value={5}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 5,
                                    }))
                                  }
                                >
                                  6.5’ X 2.5’
                                </Radio>
                                <Radio
                                  value={6}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 6,
                                    }))
                                  }
                                >
                                  6.25’ X 3’
                                </Radio>
                              </Radio.Group>
                            </TabPanel>
                            {/* Tab 2 content  */}
                            <TabPanel>
                              <Radio.Group name="radiogroup" defaultValue={1}>
                                <Radio
                                  value={1}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 7,
                                    }))
                                  }
                                >
                                  6’ X 3.5’
                                </Radio>
                                <Radio
                                  value={2}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 8,
                                    }))
                                  }
                                >
                                  4’ X 6.5’
                                </Radio>
                                <Radio
                                  value={3}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 9,
                                    }))
                                  }
                                >
                                  4’ X 6’
                                </Radio>
                              </Radio.Group>
                            </TabPanel>
                            {/* Tab 3 content  */}
                            <TabPanel>
                              <Radio.Group name="radiogroup" defaultValue={1}>
                                <Radio
                                  value={1}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 10,
                                    }))
                                  }
                                >
                                  5’ X 5.83’
                                </Radio>
                                <Radio
                                  value={2}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 11,
                                    }))
                                  }
                                >
                                  5’ X6.5’
                                </Radio>
                                <Radio
                                  value={3}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 12,
                                    }))
                                  }
                                >
                                  5’ X 6’
                                </Radio>
                              </Radio.Group>
                            </TabPanel>
                            {/* Tab 4 content  */}
                            <TabPanel>
                              <Radio.Group name="radiogroup" defaultValue={1}>
                                <Radio
                                  value={1}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 13,
                                    }))
                                  }
                                >
                                  6’ X 6’
                                </Radio>
                                <Radio
                                  value={2}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 14,
                                    }))
                                  }
                                >
                                  6.26’ X 6’
                                </Radio>
                                <Radio
                                  value={3}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      categorySizes: 15,
                                    }))
                                  }
                                >
                                  6.5’ X 6’
                                </Radio>
                              </Radio.Group>
                            </TabPanel>
                            {/* Tab 5 content  */}
                            <TabPanel>
                              <div className="custom-size">
                                <input
                                  style={{
                                    border: "1px solid #2697FF",
                                    marginRight: "7px",
                                    width: "40px",
                                  }}
                                  onChange={this.lengthHandleChange}
                                ></input>
                                X
                                <input
                                  style={{
                                    border: "1px solid #2697FF",
                                    marginLeft: "7px",
                                    width: "40px",
                                    marginRight: "7px"
                                  }}
                                  onChange={this.breadthHandleChange}
                                ></input>
                              inches
                            
                              </div>
                            </TabPanel>
                          </Tabs>
                        </div>
                        <div
                          className="thickness-conatainer"
                          style={{ marginTop: "30px" }}
                        >
                          <h4>Thickness</h4>
                          {product.price === 3380 ? (
                            <>
                              <Button text="24 inches" />
                            </>
                          ) : null}
                          {product.price === 3099 ? (
                            <>
                              <Button text="22 inches" />
                            </>
                          ) : null}
                          {product.price === 2799 ? (
                            <>
                              <Button text="20 inches" />
                            </>
                          ) : null}
                          {product.price === 2499 ? (
                            <>
                              <Button text="18 inches" />
                            </>
                          ) : null}
                          {product.price === 2190 ? (
                            <>
                              <Button text="16 inches" />
                            </>
                          ) : null}
                          {product.price === 1899 ? (
                            <>
                              <Button text="14 inches" />
                            </>
                          ) : null}
                          {product.price === 1600 ? (
                            <>
                              <Button text="12 inches" />
                            </>
                          ) : null}
                          {product.price === 1350 ? (
                            <>
                              <Button text="10 inches" />
                            </>
                          ) : null}
                          {product.price === 1050 ? (
                            <>
                              <Button text="8 inches" />
                            </>
                          ) : null}
                          {product.price === 810 ? (
                            <>
                              <Button text="6 inches" />
                            </>
                          ) : null}
                        </div>
                        <div
                          className="item-price"
                          style={{ marginTop: "20px" }}
                        >
                          <h4>Price</h4>
                          <p className="price">₹{basePrice}</p>
                        </div>
                      </>
                    ) : (
                      <div className="item-price" style={{ marginTop: "20px" }}>
                        <h4>Price</h4>
                        <p className="price">₹{product.price}</p>
                      </div>
                    )}

                    <div className="my-4 item-share">
                      <SocialShare product={product} />
                    </div>
                    <div className="item-actions">
                      {itemsInCart.includes(product._id) ? (
                        <Button
                          variant="primary"
                          disabled={
                            product.inventory <= 0 &&
                            !shopFormErrors["quantity"]
                          }
                          text="Remove From Bag"
                          className="bag-btn"
                          icon={<BagIcon />}
                          onClick={() => handleRemoveFromCart(product)}
                        />
                      ) : (
                        <Button
                          variant="primary"
                          disabled={
                            product.quantity <= 0 && !shopFormErrors["quantity"]
                          }
                          text="Add To Bag"
                          className="bag-btn"
                          icon={<BagIcon />}
                          onClick={() => handleAddToCart(product)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {user.role === "ROLE_ADMIN" ? (
              <>
                <ProductReviews
                  reviewFormData={reviewFormData}
                  reviewFormErrors={reviewFormErrors}
                  reviews={reviews}
                  reviewsSummary={reviewsSummary}
                  reviewChange={reviewChange}
                  addReview={addProductReview}
                />
              </>
            ) : null}
          </>
        ) : (
          <NotFound message="no product found." />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.product.storeProduct,
    productShopData: state.product.productShopData,
    shopFormErrors: state.product.shopFormErrors,
    isLoading: state.product.isLoading,
    reviews: state.review.productReviews,
    reviewsSummary: state.review.reviewsSummary,
    reviewFormData: state.review.reviewFormData,
    reviewFormErrors: state.review.reviewFormErrors,
    itemsInCart: state.cart.itemsInCart,
    user: state.account.user,
    categories: state.category.storeCategories,
  };
};

export default connect(mapStateToProps, actions)(ProductPage);
