/**
 *
 * ProductList
 *
 */

 import React from "react";
 import { Link } from "react-router-dom";
import AddToWishList from "../Store/AddToWishList";
 
 
 const ProductList = (props) => {
   const { products, updateWishlist, authenticated } = props;
 
   console.log("productList", products);
 
 
   return (
     <div className="product-listt">
         <div className="mb-3 mb-md-0">
           <div className="product-container" style={{margin: "20px"}}>
             <div className="item-box">
 
               <div className="item-link">
                 <Link to={`/product/${props.slug}`}>
                   <div className="item-image-container">
                     <div className="item-image-box">
                       <img
                         className="item-image"
                         src={props.img}
                       />
                     </div>
                   </div>
                   <div className="item-body">
                     <div className="item-details p-3">
                       <h1 className="item-name">{props.name}</h1>
                       
                         <p className="by">
                           By <span>{props.name}</span>
                         </p>
                       <p className="item-desc mb-0">{props.description}</p>
                     </div>
                   </div>
                   {/* <div className="d-flex flex-row justify-content-between align-items-center px-4 mb-2 item-footer">
                     <p className="price mb-0">₹{product.price}</p>
                     {product.totalReviews > 0 && (
                       <p className="mb-0">
                         <span className="fs-16 fw-1 mr-1">
                           {parseFloat(product?.averageRating).toFixed(1)}
                         </span>
                         <span
                           className={`fa fa-star ${
                             product.totalReviews !== 0 ? "checked" : ""
                           }`}
                           style={{ color: "#ffb302" }}
                         ></span>
                       </p>
                     )}
                   </div> */}
                 </Link>
               </div>
             </div>
           </div>
         </div>
     </div>
   );
 };
 
 export default ProductList;
 