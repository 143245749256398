/*
 *
 * NavigationMenu actions
 *
 */

import { DEFAULT_ACTION } from "./constants";

export const defaultAction = () => {
  return {
    type: DEFAULT_ACTION,
  };
};
