import React from "react";
import "../../App.css";

const Policy = () => {
  return (
    <div className="policy-container" style={{ padding: "30px" }}>
      <h1 style={{ textAlign: "center" }}>Sleep Elements - Privacy Policy</h1>
      <p>
        We, at Sleep Elements, work hard to earn your trust and strive to protect your
        privacy at all times.
      </p>
      <h2>1. Personal information</h2>
      <p>
        We collect personal information (Name, Email id, Phone number, Address
        etc.,) from you during the course of transaction or at the time of
        account creation. We use this information from time to time to contact
        you for offers unless you explicitly opt out of such offers.
      </p>
      <h2>2. Use of demographic and profile data</h2>
      <p>
        In our efforts to continually improve our product and service offerings,
        we collect and analyze demographic and profile data about our users'
        activity on our website. We identify and use your IP address to help
        diagnose problems with our server, and to administer our website. Your
        IP address is also used to help identify you and to gather broad
        demographic information. We will occasionally ask you to complete
        optional online surveys. These surveys may ask you for contact
        information and demographic information (like pin code, age, or income
        level). We use this data to tailor your experience at our site,
        providing you with content that we think you might be interested in--and
        to display content according to your preferences.
      </p>
      <h2>3. Sharing of personal information</h2>
      <p>
        We may share personal information to comply with any governmental ruling
        or without own affiliates for the sake of data analysis. Sharing with
        any third party is done only if the user has explicitly agreed to such
        sharing.
      </p>
      <h2>4. Security precautions</h2>
      <p>
        Our site has stringent security measures in place to protect the loss,
        misuse, and alteration of the information under our control. Whenever
        you change or access your account information, we offer the use of a
        secure server. Once your information is in our possession we adhere to
        strict security guidelines, protecting it against unauthorized access.
      </p>
      <h2>5. Digital advertising & cookie policy</h2>
      <p>
        When visitors come to our website, third parties may place cookies on
        visitors' browsers for targeted advertising purposes <br />
        <br />
        We collect the following categories of information:
      </p>
      <p style={{ marginLeft: "20px" }}>
        1. Activity on Advertisers’ Digital Properties: This is data about your
        browsing activity on the Advertiser's website or app. For example, which
        pages you visited and when, what items were clicked on a page, how much
        time was spent on a page, whether you downloaded a white paper on a
        business to business website, what items you placed into your online
        shopping cart, what products were purchased and how much was paid.
      </p>
      <p style={{ marginLeft: "20px" }}>
        2. Device and browser information: This is technical information about
        the device or browser you use to access the Advertiser's website. For
        example, your device's IP address, cookie string data, operating system,
        and (in the case of mobile devices) your device type and mobile device's
        unique identifier such as the Apple IDFA or Android Advertising ID.
      </p>
      <p style={{ marginLeft: "20px" }}>
        3. Ad data: This is data about the online ads we have served (or
        attempted to serve) to you. It includes things like how many times an ad
        has been served to you, what page the ad appeared on, and whether you
        clicked on or otherwise interacted with the ad.
      </p>
      <p>
        In case, you would like to opt-out, please follow the directions below:
        <br />
        <br />
        We recognize how important your online privacy is to you, so we offer
        the following options for controlling the targeted ads you receive and
        how we use your data:
      </p>
      <p style={{ marginLeft: "20px" }}>
        ⚫ You can opt out of receiving personalized ads served by us or on our
        behalf by clicking on the blue icon that typically appears in the corner
        of the ads we serve and following the instructions provided or by
        clicking here. Please note that this “opt out” function is
        browser-specific and relies on an “opt out cookie”: thus, if you delete
        your cookies or upgrade your browser after having opted out, you will
        need to opt out again.
      </p>
      <p style={{ marginLeft: "20px" }}>
        ⚫ In some cases we may link multiple browsers or devices to you. If you
        opt out of on a browser or device and we have more linked to you, we
        will extend your opt out decision to the other linked browsers and
        devices. Since we only link users across browsers on devices in some
        conditions, there could be cases where you are still being tracked in a
        different browser or device we have not linked, and where we are
        treating you as a different user.
      </p>
      <p style={{ marginLeft: "20px" }}>
        ⚫ We adhere to the NAI Codes of Conduct. You may use the NAI opt out
        tool here, which will allow you to opt out of seeing personalized ads
        from us and from other NAI approved member companies.
      </p>
      <p style={{ marginLeft: "20px" }}>
        ⚫ We also comply with the Self-Regulatory Principles for Online
        Behavioral Advertising as managed by the Digital Advertising Alliance
        (DAA). You may opt out of receiving personalized ads from other
        companies that perform ad targeting services, including some that we may
        work with as Advertising Partners via the DAA website here.
      </p>
      <p style={{ marginLeft: "20px" }}>
        ⚫ We also comply with the Canadian Self-regulatory Principles for
        Online Behavioral Advertising as managed by the Digital Advertising
        Alliance of Canada (DAAC). You may opt out of receiving personalized ads
        from other companies that perform ad targeting services, including some
        that we may work with as Advertising Partners via the DAAC website here.
      </p>
      <p style={{ marginLeft: "20px" }}>
        ⚫ We also adhere to the European Interactive Advertising Digital
        Alliance (EDAA) guidelines for online advertising and you may opt out
        via their Your Online Choices website.
      </p>
      <p>
        Opting out will not prevent you from seeing ads, but those ads will
        likely be less relevant because they won’t be tailored to your
        interests. The ads might, for instance, be randomly generated or based
        on the web page you are visiting. <br />
        <br />
        Some internet browsers allow users to send a "Do Not Track" signal to
        websites they visit. We do not respond to this signal at the present
        time. <br />
        <br />
        In addition, if you are located in a European Territory you will also
        have additional data protection rights. These are described under the
        heading "Additional data protection rights for European Territory
        residents" below. <br />
        <br />
        This policy is subject to change without notice. <br />
        <br />
      </p>
    </div>
  );
};

export default Policy;
