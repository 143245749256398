/**
 *
 * EditProduct
 *
 */

import React from "react";

import { Row, Col } from "reactstrap";

import Input from "../../Common/Input";
import Switch from "../../Common/Switch";
import Button from "../../Common/Button";
import SelectOption from "../../Common/SelectOption";
import axios, * as others from "axios";
import { useState } from "react";

const taxableSelect = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

const EditProduct = (props) => {
  const {
    user,
    product,
    productChange,
    formErrors,
    brands,
    updateProduct,
    deleteProduct,
    activateProduct,
    image,
    productImageUpload,
    productFormData,
  } = props;

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  console.log("state img", selectedFiles);

  const fileSelectedHandler = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const newImage = event.target.files[i];
      newImage["id"] = Math.random();
      setSelectedFiles((prevState) => [...prevState, newImage]);
    }

    console.log(selectedFiles);
    // setSelectedFile(event.target.files[0]);
  };
  const fileUploadHandler = (event) => {
    selectedFiles.map((selectedFile) => {
      const fd = new FormData();
      fd.append("image", selectedFile, selectedFile.name);
      productImageUpload(product._id, fd);
      // axios.post(`/api/upload/product/${product._id}`, fd).then((res) => {
      //   console.log("Fileee", res);
      // });
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateProduct();
    // productImageUpload(product._id, formData);
  };

  return (
    <div className="edit-product">
      <form onSubmit={handleSubmit} noValidate>
        <Row>
          <Col xs="12">
            <Input
              type={"text"}
              error={formErrors["name"]}
              label={"Name"}
              name={"name"}
              placeholder={"Product Name"}
              value={product.name}
              onInputChange={(name, value) => {
                productChange(name, value);
              }}
            />
          </Col>
          <Col xs="12" md="12">
            <Input
              type={"textarea"}
              error={formErrors["description"]}
              label={"Description"}
              name={"description"}
              placeholder={"Product Description"}
              value={product.description}
              onInputChange={(name, value) => {
                productChange(name, value);
              }}
            />
          </Col>
          <Col xs="12" lg="6">
            <Input
              type={"number"}
              error={formErrors["quantity"]}
              label={"Quantity"}
              name={"quantity"}
              decimals={false}
              placeholder={"Product Quantity"}
              value={product.quantity}
              onInputChange={(name, value) => {
                productChange(name, value);
              }}
            />
          </Col>
          <Col xs="12" lg="6">
            <Input
              type={"number"}
              error={formErrors["price"]}
              label={"Price"}
              name={"price"}
              min={1}
              placeholder={"Product Price"}
              value={product.price}
              onInputChange={(name, value) => {
                productChange(name, value);
              }}
            />
          </Col>
          <Col xs="12" md="12">
            <SelectOption
              error={formErrors["taxable"]}
              label={"Taxable"}
              multi={false}
              name={"taxable"}
              value={[product.taxable ? taxableSelect[0] : taxableSelect[1]]}
              options={taxableSelect}
              handleSelectChange={(value) => {
                productChange("taxable", value.value);
              }}
            />
          </Col>
          {user.role === "ROLE_ADMIN" && (
            <>
              <Col xs="12" md="12">
                <SelectOption
                  error={formErrors["brand"]}
                  label={"Select Brand"}
                  multi={false}
                  value={product.brand}
                  options={brands}
                  handleSelectChange={(value) => {
                    productChange("brand", value);
                  }}
                />
              </Col>
              <Col xs="12" md="12">
                <input
                  id="files"
                  type={"file"}
                  error={formErrors["file"]}
                  name={"image"}
                  label={"file"}
                  placeholder={"Please Upload Image"}
                  // value={image}
                  onChange={fileSelectedHandler}
                  multiple="multiple"
                />
                {/* <button >Upload</button> */}
              </Col>
            </>
          )}
          <Col xs="12" md="12" className="mt-3 mb-2">
            <Switch
              id={`enable-product-${product._id}`}
              name={"isActive"}
              label={"Active?"}
              checked={product?.isActive}
              toggleCheckboxChange={(value) => {
                productChange("isActive", value);
                activateProduct(product._id, value);
              }}
            />
          </Col>
        </Row>
        <hr />
        <div className="d-flex flex-column flex-md-row">
          <Button
            type="submit"
            text="Save"
            className="mb-3 mb-md-0 mr-0 mr-md-3"
            // onClick={submitFileData}
            onClick={fileUploadHandler}
          />
          <Button
            variant="danger"
            text="Delete"
            onClick={() => deleteProduct(product._id)}
          />
        </div>
      </form>
    </div>
  );
};

export default EditProduct;
