/*
 *
 * Account constants
 *
 */

export const ACCOUNT_CHANGE = "src/Account/ACCOUNT_CHANGE";
export const FETCH_PROFILE = "src/Account/FETCH_PROFILE";
export const CLEAR_ACCOUNT = "src/Account/CLEAR_ACCOUNT";
export const SET_PROFILE_LOADING = "src/Account/SET_PROFILE_LOADING";
