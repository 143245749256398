/*
 *
 * ResetPassword constants
 *
 */

export const RESET_PASSWORD_CHANGE = "src/ResetPassword/RESET_PASSWORD_CHANGE";
export const RESET_PASSWORD_RESET = "src/ResetPassword/RESET_PASSWORD_RESET";
export const SET_RESET_PASSWORD_FORM_ERRORS =
  "src/ResetPassword/SET_RESET_PASSWORD_FORM_ERRORS";
