/*
 *
 * Newsletter constants
 *
 */

export const NEWSLETTER_CHANGE = "src/Newsletter/NEWSLETTER_CHANGE";
export const SET_NEWSLETTER_FORM_ERRORS =
  "src/Newsletter/SET_NEWSLETTER_FORM_ERRORS";
export const NEWSLETTER_RESET = "src/Newsletter/NEWSLETTER_RESET";
