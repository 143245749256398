import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { connect } from "react-redux";

import actions from "../../actions";
import LoadingIndicator from "../Common/LoadingIndicator";
import FeaturedCard from "../FeaturedCard/FeaturedCard";
import ProductList from "../Store/ProductList";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
class FeaturedProducts extends React.Component {
  componentDidMount() {
    this.props.fetchProducts();
  }                     
  render() {
    const { products, isLoading } = this.props;
    console.log("FEATURED", products);
    return (
      <>
        {isLoading ? (
          <LoadingIndicator inline />
        ) : (
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true}
            autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            // customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {products.map((product, index) => (
              <div key={index}>
                <FeaturedCard
                  name={product.name}
                  slug={product.slug}
                  description={product.description}
                  img={product.imageUrl}
                  brand={product.brand.name}
                  product={product}
                />
              </div>
            ))}
          </Carousel>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.product.storeProducts,
    isLoading: state.product.isLoading,
  };
};

export default connect(mapStateToProps, actions)(FeaturedProducts);
