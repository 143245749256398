/**
 *
 * ProductFilter
 *
 */

import React, { useEffect } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Container } from "reactstrap";
import RangeSlider from "../../Common/RangeSlider";
import StarIcon from "@mui/icons-material/Star";
import actions from "../../../actions";
import Button from "../../../components/Common/Button";
import { CloseIcon } from "../../../components/Common/Icon";

const priceMarks = {
  500: { label: <p className="fw-1 text-black">₹5000</p> },
  1000000: { label: <p className="fw-1 text-black">₹1000000</p> },
};

const rateMarks = {
  0: {
    label: (
      <strong>
        5<StarIcon style={{ color: "#ffb302" }} />
      </strong>
    ),
  },
  20: {
    label: (
      <strong>
        4<StarIcon style={{ color: "#ffb302" }} />
      </strong>
    ),
  },
  40: {
    label: (
      <strong>
        3<StarIcon style={{ color: "#ffb302" }} />
      </strong>
    ),
  },
  60: {
    label: (
      <strong>
        2<StarIcon style={{ color: "#ffb302" }} />
      </strong>
    ),
  },
  80: {
    label: (
      <strong>
        1<StarIcon style={{ color: "#ffb302" }} />
      </strong>
    ),
  },
  100: { label: <strong>Any</strong> },
};

const rating = (v) => {
  switch (v) {
    case 100:
      return 0;
    case 80:
      return 1;
    case 60:
      return 2;
    case 40:
      return 3;
    case 20:
      return 4;
    default:
      return 5;
  }
};

class ProductFilter extends React.Component {
  componentDidMount(){ 
    this.props.filterProducts();
  }
  render() {
    const { isMenuOpen, filterProducts, categories, toggleMenu } = this.props;

    const handleCategoryClick = () => {
      this.props.toggleMenu();
    };
    return (
      <div className="product-filter" style={{ marginTop: "27px" }}>
        <Card className="mb-4">
          <CardHeader tag="h3">Shop By Category</CardHeader>
          <CardBody>
            <div className="navigation-menu">
              <div className="menu-body">
                <Container className="categorySelect">
                  <nav role="navigation">
                    <ul className="menu-list" style={{ paddingLeft: "0" }}>
                    <li className="menu-item">
                          <NavLink
                            // onClick={handleCategoryClick}
                            to={"/shop"}
                            activeClassName="active-link"
                            exact
                          >
                            All
                          </NavLink>
                        </li>
                      {categories.map((link, index) => (
                        <li key={index} className="menu-item">
                          <NavLink
                            // onClick={handleCategoryClick}
                            to={"/shop/category/" + link.slug}
                            activeClassName="active-link"
                            exact
                          >
                            {link.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </Container>
              </div>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardHeader tag="h3">Rating</CardHeader>
          <CardBody>
            <div className="mx-2 mb-4">
              <RangeSlider
                type="slider"
                marks={rateMarks}
                step={20}
                defaultValue={100}
                onChange={(v) => {
                  filterProducts("rating", rating(v));
                }}
              />
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMenuOpen: state.navigation.isMenuOpen,
    categories: state.category.storeCategories,
  };
};

export default connect(mapStateToProps, actions)(ProductFilter);
