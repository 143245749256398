/**
 *
 * Summary
 *
 */

// import React from "react";

import ReactStars from "react-rating-stars-component";

// import NotFound from "../../Common/NotFound";

/*
 *
 * Review
 *
 */

import React from "react";
import { connect } from "react-redux";
import { ReviewIcon } from "../../Common/Icon";
import actions from "../../../actions";

import SubPage from "../../../components/Manager/SubPage";
import LoadingIndicator from "../../../components/Common/LoadingIndicator";
import NotFound from "../../../components/Common/NotFound";
import ReviewList from "../../../components/Manager/ReviewList";
import ReviewListForProductPage from "../../Common/ReviewListForProductPage";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
// class Summary extends React.PureComponent {
//   componentDidMount() {
//     this.props.fetchReviews();
//   }

//   render() {
//     const {
//       product,
//       reviews,
//       isLoading,
//       approveReview,
//       rejectReview,
//       deleteReview,
//     } = this.props;
//     console.log("sadfasfds", reviews);
//     console.log("product", product);

//     return (
//       <div className="review-dashboard">
//         <SubPage title={"Reviews"} isMenuOpen={null}>
//           {isLoading ? (
//             <LoadingIndicator inline />
//           ) : reviews.length > 0 ? (
//             <ReviewListForProductPage reviews={reviews} />
//           ) : (
//             <NotFound>
//               <ReviewIcon width="40" height="40" className="my-2" />
//               <p className="mb-2">Be the first to add a review.</p>
//             </NotFound>
//           )}
//         </SubPage>
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => {
//   return {
//     reviews: state.review.reviews,
//     isLoading: state.review.isLoading,
//   };
// };

// export default connect(mapStateToProps, actions)(Summary);

const Summary = (props) => {
  const {
    reviewsSummary: { ratingSummary, totalRatings, totalReviews, totalSummary },
  } = props;

  const getRatingPercentage = (value) => {
    return parseFloat(percentage(value, totalSummary).toFixed(2));
  };

  const averageRating =
    totalRatings > 0 && Math.round(totalRatings / totalReviews);

  return (
    <div className="bg-white p-4 box-shadow-primary review-summary">
      <h2 className="mb-0">Rating</h2>
      {averageRating && (
        <div className="d-flex flex-wrap align-items-center mt-2">
          <ReactStars
            classNames="mr-2"
            size={17}
            edit={false}
            color={"#adb5bd"}
            activeColor={"#ffb302"}
            a11y={true}
            isHalf={true}
            emptyIcon={<StarIcon />}
            halfIcon={<StarHalfIcon />}
            filledIcon={<StarIcon />}
            value={averageRating}
          />
          {totalReviews > 0 && <span>based on {totalReviews} reviews.</span>}
        </div>
      )}

      <hr style={{ border: "3px solid #f1f1f1" }} />
      {totalReviews > 0 ? (
        ratingSummary.map((r, obj) => (
          <div key={obj} className="d-flex align-items-center mb-2">
            <div className="left">
              <span>{parseInt(Object.keys(r)[0])} star</span>
            </div>
            <div className="middle">
              <div className="bar-container">
                <div
                  className={`bar-${parseInt(Object.keys(r)[0])}`}
                  style={{
                    width: `${getRatingPercentage(
                      parseInt(r[Object.keys(r)[0]])
                    )}%`,
                  }}
                ></div>
              </div>
            </div>
            <div className="ml-2 right">
              <span className="fw-2">
                {getRatingPercentage(parseInt(r[Object.keys(r)[0]]))}%
              </span>
            </div>
          </div>
        ))
      ) : (
        <NotFound>
          <ReviewIcon width="40" height="40" className="my-2" />
          <p className="mb-2">Be the first to add a review.</p>
        </NotFound>
      )}
    </div>
  );
};

export default Summary;

function percentage(partialValue, totalValue) {
  return (100 * partialValue) / totalValue;
}
