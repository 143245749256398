/**
 *
 * AddProduct
 *
 */

import React from "react";
import axios from "axios";
import { Row, Col } from "reactstrap";

import Input from "../../Common/Input";
import Switch from "../../Common/Switch";
import Button from "../../Common/Button";
import SelectOption from "../../Common/SelectOption";

const taxableSelect = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

const AddProduct = (props) => {
  const {
    user,
    productFormData,
    formErrors,
    productChange,
    addProduct,
    brands,
    image,
  } = props;

  const handleSubmit = (event) => {
    event.preventDefault();
    addProduct();
  };

  return (
    <div className="add-product">
      <h1 />
      <form onSubmit={handleSubmit} noValidate>
        <Row>
          <Col xs="12" lg="6">
            <Input
              type={"text"}
              error={formErrors["sku"]}
              label={"Sku"}
              name={"sku"}
              placeholder={"Product Sku"}
              value={productFormData.sku}
              onInputChange={(name, value) => {
                productChange(name, value);
              }}
            />
          </Col>
          <Col xs="12" lg="6">
            <Input
              type={"text"}
              error={formErrors["name"]}
              label={"Name"}
              name={"name"}
              placeholder={"Product Name"}
              value={productFormData.name}
              onInputChange={(name, value) => {
                productChange(name, value);
              }}
            />
          </Col>
          <Col xs="12" md="12">
            <Input
              type={"textarea"}
              error={formErrors["description"]}
              label={"Description"}
              name={"description"}
              placeholder={"Product Description"}
              value={productFormData.description}
              onInputChange={(name, value) => {
                productChange(name, value);
              }}
            />
          </Col>
          <Col xs="12" lg="6">
            <Input
              type={"number"}
              error={formErrors["quantity"]}
              label={"Quantity"}
              name={"quantity"}
              decimals={false}
              placeholder={"Product Quantity"}
              value={productFormData.quantity}
              onInputChange={(name, value) => {
                productChange(name, value);
              }}
            />
          </Col>
          <Col xs="12" lg="6">
            <Input
              type={"number"}
              error={formErrors["price"]}
              label={"Price"}
              name={"price"}
              min={1}
              placeholder={"Product Price"}
              value={productFormData.price}
              onInputChange={(name, value) => {
                productChange(name, value);
              }}
            />
          </Col>
          <Col xs="12" md="12">
            <SelectOption
              error={formErrors["taxable"]}
              label={"Taxable"}
              name={"taxable"}
              options={taxableSelect}
              value={productFormData.taxable}
              handleSelectChange={(value) => {
                productChange("taxable", value);
              }}
            />
          </Col>
          <Col xs="12" md="12">
            <SelectOption
              disabled={user.role === "ROLE_MERCHANT"}
              error={formErrors["brand"]}
              name={"brand"}
              label={"Select Brand"}
              value={
                user.role === "ROLE_MERCHANT"
                  ? brands[1]
                  : productFormData.brand
              }
              options={brands}
              handleSelectChange={(value) => {
                productChange("brand", value);
              }}
            />
          </Col>
          <Col xs="12" md="12" className="my-2">
            <Switch
              id={"active-product"}
              name={"isActive"}
              label={"Active?"}
              checked={productFormData.isActive}
              toggleCheckboxChange={(value) => productChange("isActive", value)}
            />
          </Col>
        </Row>
        <hr />
        <div className="add-product-actions">
          <Button type="submit" text="Add Product"/>
        </div>
      </form>
    </div>
  );
};

export default AddProduct;
