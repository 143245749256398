import React from "react";
import bgpic from "../images/bg-pic2.jpg";

const HeroSection = () => {
  return (
    <div className="hero-container">
      <img className="bg-img" src={bgpic} alt="BG_PIC" />
      <div className="hero-centered" style={{top: "38%"}}>
        <div className="w-header">Better Sleep, Better Living.</div>
        <div className="w-body" style={{fontWeight: "600"}}>Experience a mattress made for everybody !</div>
      </div>
    </div>
  );
};

export default HeroSection;
