/**
 *
 *  Range Slider
 *
 */

import React from "react";
import Slider, { SliderTooltip } from "rc-slider";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const { Handle } = Slider;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`$${value}`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

class RangeSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderValue: 500,
      rangeValue: [500, 1000000],
    };
  }

  onSliderChange = (v) => {
    this.setState({
      sliderValue: v,
    });
  };

  onRangeChange = (v) => {
    this.setState({
      rangeValue: v,
    });
  };

  onAfterSliderChange = (value) => {
    this.props.onChange(value);
  };

  onAfterRangeChange = (value) => {
    this.props.onChange(value);
  };

  render() {
    const { type, marks, step, defaultValue } = this.props;
    const { sliderValue, rangeValue } = this.state;

    return (
      <>
        {type === "slider" ? (
          <Slider
            className="slider"
            dots
            reverse
            step={step}
            defaultValue={defaultValue}
            marks={marks}
            value={sliderValue}
            onChange={this.onSliderChange}
            onAfterChange={this.onAfterSliderChange}
          />
        ) : (
          <Range
            className="slider"
            pushable={100}
            min={500}
            max={1000000}
            marks={marks}
            handle={handle}
            tipFormatter={(value) => `$${value}`}
            defaultValue={defaultValue}
            value={rangeValue}
            onChange={this.onRangeChange}
            onAfterChange={this.onAfterRangeChange}
          />
        )}
      </>
    );
  }
}

RangeSlider.defaultProps = {
  type: "range",
};

export default RangeSlider;
