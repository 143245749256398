import React from "react";
import guarantee from "../../images/guarantee.png";
import allergi from "../../images/Sleep Elements Resources/bacteria.png";
import foam from "../../images/Sleep Elements Resources/bed-sheets.png";
import edge from "../../images/Sleep Elements Resources/brick.png";
import plant from "../../images/Sleep Elements Resources/plant.png";
import warranty from "../../images/Sleep Elements Resources/warranty.png";
import "../whyOurProducts/WhyOurProducts.css";

const WhyOurProducts = () => {
  return (
    <div className="WhyOurProducts-cont">
      <div className="title">
        <h1>
          Why our <br /> products?
        </h1>
      </div>
      <div className="advantages">
        <div className="advantage1">
          <div className="advImg1">
            <div className="circle"></div>
            <img src={guarantee} alt="guarantee" />
          </div>
          <div className="advTitle1">
            <h4>Experience</h4>
          </div>
          <div className="advDescription1">
            <span>We are sure about our quality</span>
          </div>
        </div>
        <div className="advantage2">
          <div className="advImg2">
            <div className="circle"></div>
            <img src={allergi} alt="allergi" />
          </div>
          <div className="advTitle2">
            <h4>For allergic persons</h4>
          </div>
          <div className="advDescription2">
            <span>All our products are suitable for allergic persons</span>
          </div>
        </div>
        <div className="advantage3">
          <div className="advImg3">
            <div className="circle"></div>
            <img src={plant} alt="plant" />
          </div>
          <div className="advTitle3">
            <h4>Natural</h4>
          </div>
          <div className="advDescription3">
            <span>
              Organic ceramics and ingredients used only which passes through
              the body
            </span>
          </div>
        </div>

        <div className="advantage1">
          <div className="advImg1">
            <div className="circle"></div>
            <img src={foam} alt="guarantee" />
          </div>
          <div className="advTitle1">
            <h4>Bio High Density Foam</h4>
          </div>
          <div className="advDescription1">
            <span>We are sure about our quality</span>
          </div>
        </div>
        <div className="advantage2">
          <div className="advImg2">
            <div className="circle"></div>
            <img src={edge} alt="allergi" />
          </div>
          <div className="advTitle2">
            <h4>Quantum Edge</h4>
          </div>
          <div className="advDescription2">
            <span>Elite Pocketed Coil System</span>
          </div>
        </div>
        <div className="advantage3">
          <div className="advImg3">
            <div className="circle"></div>
            <img src={warranty} alt="plant" />
          </div>
          <div className="advTitle3">
            <h4>10 years of warranty</h4>
          </div>
          <div className="advDescription3">
            <span>Get a warranty shield of 10 years</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyOurProducts;
