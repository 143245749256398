import React from "react";
import { connect } from "react-redux";
import actions from "../../actions";
import CarouselSlider from "../../components/Common/CarouselSlider";
import { responsiveOneItemCarousel } from "../../components/Common/CarouselSlider/helpers";
import CompanyInfo from "../../components/CompanyInfo";
import Guarantee from "../../components/guarantee/Guarantee";
import WebsiteReviews from "../../components/websiteReviews/WebsiteReviews";
import HeroSection from "../../components/HeroSection";
import InfoSection from "../../components/InfoSection";
import WhyOurProducts from "../../components/whyOurProducts/WhyOurProducts";
import img1 from "../../images/browseCategories/img1.jpg";
import img2 from "../../images/browseCategories/img2.jpg";
import img3 from "../../images/browseCategories/img3.jpg";
import img4 from "../../images/browseCategories/img4.jpg";
import banners from "./banners.json";
import FeaturedProducts from "../../components/FeaturedProducts/FeaturedProducts";

class Homepage extends React.PureComponent {
  render() {
    return (
      <div className="homepage">
        <HeroSection />
        <InfoSection />
        <div className="featuredProducts" style={{ marginBottom: "30px" }}>
          <div className="carosel-header">
            <div>Featured Products</div>
          </div>
          <FeaturedProducts />
        </div>
        {/* <div className="carosel-header">
          <div>Mattress for the Best of Reasons</div>
        </div>
        <div className="home-carousel">
          <CarouselSlider
            swipeable={true}
            showDots={true}
            infinite={true}
            autoPlay={false}
            slides={banners}
            responsive={responsiveOneItemCarousel}
          >
            <div>
              <img src={img1} />
              <p className="bottom-left color-white">Best in Style</p>
            </div>
            <div>
              <img src={img2} />
              <p className="bottom-left color-white">Legendary Comfort</p>
            </div>
            <div>
              <img src={img3} />
              <p className="bottom-left color-black">Available in Sizes</p>
            </div>
            <div>
              <img src={img4} />
              <p className="bottom-left color-black">
                A Mattress for every purpose
              </p>
            </div>
          </CarouselSlider>
        </div> */}
        <CompanyInfo />
        <WhyOurProducts />
        <Guarantee />
        <WebsiteReviews />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, actions)(Homepage);
