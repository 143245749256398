import React from "react";
import quote from "../../images/Sleep Elements Resources/left-quote.png";
import lazy from "../../images/Sleep Elements Resources/lazy.jpg";
import circledleft from "../../images/Sleep Elements Resources/circled-left.png";
import circledright from "../../images/Sleep Elements Resources/circled-right.png";
import "./WebsiteReviews.css";
import Avatar from "react-avatar";
import ReactStars from "react-rating-stars-component";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import { connect } from "react-redux";
import { fetchWebsiteReviews } from "./actions";
import WebsiteReviewCard from "./WebsiteReviewCard";

class Reviews extends React.PureComponent {

  componentDidMount() {
    this.props.fetchWebsiteReviews();
  }
  
  render() {
    const {websiteReviews} = this.props
    const user = ["@danish", "@Rajarshari", "@Ayaan"]
    return (
      <div className="Reviews-cont">
        <div className="Apfgp">
          {/* <!--heading---> */}
          <div class="testimonial-heading">
            <span>CUSTOMER REVIEWS</span>
          </div>
            <div className="reviewCard">
              <WebsiteReviewCard/>
            </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    websiteReviews: state.websiteReview.data,
  };
};

export default connect(mapStateToProps, { fetchWebsiteReviews })(Reviews);
