import React from "react";

const InfoBox = (props) => {
  return (
    <div className="info-box">
      <div>
        <img className="info-img" src={props.img} alt="INFO_IMG" />
      </div>
      <div className="info-title">{props.title}</div>
      <div className="info-details">{props.details}</div>
    </div>
  );
};

export default InfoBox;
