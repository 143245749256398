/*
 *
 * Review constants
 *
 */

export const FETCH_REVIEWS = "src/Review/FETCH_REVIEWS";
export const ADD_REVIEW = "src/Review/ADD_REVIEW";
export const REMOVE_REVIEW = "src/Review/REMOVE_REVIEW";
export const FETCH_PRODUCT_REVIEWS = "src/Review/FETCH_PRODUCT_REVIEWS";
export const REVIEW_CHANGE = "src/Review/REVIEW_CHANGE";
export const SET_REVIEWS_LOADING = "src/Review/SET_REVIEWS_LOADING";
export const RESET_REVIEW = "src/Review/RESET_REVIEW";
export const SET_REVIEW_FORM_ERRORS = "src/Review/SET_REVIEW_FORM_ERRORS";
