/*
 *
 *  website Review reducer
 *
 */

import { FETCH_WEBSITE_REVIEWS } from "./constants";

const initialState = {
  data: [],
  title: "",
  review: "",
  isRecommended: {
    status: 0,
  },
};

const websiteReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WEBSITE_REVIEWS:
      console.log("ggjgmgj", state);
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default websiteReviewReducer;
