import React from "react";
import "../guarantee/Guarantee.css";

const Guarantee = () => {
  return (
    <div className="guarantee-cont">
      <div className="cont1">
        <h1>Sleep Elements</h1>
        <span>Satisfaction Guarantee !</span>
      </div>
      <div className="cont2">
        <h1>Try it for 100 nights !</h1>
        <h1>Ultimately we think you'll</h1>
        <h1>Fall in love with it !</h1>
      </div>
    </div>
  );
};

export default Guarantee;
