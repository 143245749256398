import React from "react";
import guarantee from "../images/guarantee.png";
import moon from "../images/moon.png";
import shipped from "../images/shipped.png";
import CompanyInfoBox from "./CompanyInfoBox";
const CompanyInfo = () => {
  return (
    <div className="infocont">
      <CompanyInfoBox
        img={guarantee}
        text="Best in class 10 years of warranty"
      />
      <CompanyInfoBox img={moon} text="100 nights Risk Free Trial" />
      <CompanyInfoBox img={shipped} text="Free Shipping all over India" />
    </div>
  );
};

export default CompanyInfo;
